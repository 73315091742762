import request from '@/utils/request';

export const loginApi = (params) => {
  return request({
    url: '/login/login',
    method: 'get',
    params
  });
};

export const wx_loginApi = (params) => {
  return request({
    url: '/login/wx_login',
    method: 'get',
    params
  });
};

export const wxredirect_url = (params) => {
  return request({
    url: '/login/redirect_url',
    method: 'get',
    params
  });
};

export const registerApi = (data) => {
  return request({
    url: '/login/register',
    method: 'post',
    data
  });
};

export const phone_send = (data) => {
  return request({
    url: '/tool/phone_send',
    method: 'post',
    data
  });
};
/**
 * 重置密码
 * @params
 */
export const resetpassword = (params) => {
  return request({
    url: '/login/forget_password',
    method: 'post',
    params
  });
};

export const price = (params) => {
  return request({
    url: '/index/price',
    method: 'post',
    params
  });
};

export const order_list = (params) => {
  return request({
    url: '/user/order_list',
    method: 'get',
    params
  });
};

export const config = () => {
  return request({
    url: '/index/config',
    method: 'get',
    params: { type: 1 }
  });
};

export const point_list = (params) => {
  return request({
    url: '/user/point_list',
    method: 'get',
    params
  });
};
